import React from 'react';
import "./HomePage.css";
import "../../assets/css/style.css";
import Header from "../../components/HeaderComponent/Header";
import Footer from "../../components/FooterComponent/Footer";
import letuciycelovek from "../../assets/images/letuciycelovek.png";
import ademinsaat from '../ProjectsPage/img/ademinsaat.jpg'
import setkaya from '../ProjectsPage/img/setkaya.jpg'
import oilstart from '../ProjectsPage/img/oilstart.jpeg'
function HomePage() {

    // const handleButtonClick = () => {
    //     navigate('/login'); // Путь к странице логина
    // };

    return (
        <>
            <div className='container'>
                <Header />
                <div className='home-page-container'>
                    <div className='better-business-part'>
                        <div className="containerDigigo">
                        <div className='left-side'>
                          


                            <p><span> Digigo</span> - Go to digital future </p>
                            <p>
                            At Digigo , we harness a network of skilled professionals to bring your projects to life—efficiently, expertly, and on time. From cutting-edge applications to seamless web experiences and secure digital environments, we specialize in tailored solutions that meet your unique needs. Let us turn your vision into reality with the precision and quality it deserves.
                            </p>
                            {/* <button onClick={handleButtonClick}>
                                Get Started
                            </button> */}
                        </div>
                        <div className='right-side'>
                            <img className='letuciycelovek' src={letuciycelovek} alt="" />
                        </div>

                        </div>
                    </div>
                    <div className='services-home-page'>
                        <p className='header-txt'>Our <span> Services</span></p>
                        <div className='service-wrap'>
                            <div className='service-box'>
                                <p>Data analitika</p>
                                {/* <p>It is a long established fact that a reader will be</p> */}
                            </div>
                            <div className='service-box'>
                                <p>cybersecurity</p>
                                {/* <p>It is a long established fact that a reader will be</p> */}
                            </div>
                            <div className='service-box'>
                                <p>Mobile and Web Development</p>
                                {/* <p>It is a long established fact that a reader will be</p> */}
                            </div>
                            <div className='service-box'>
                                <p>Ux/Ui Design</p>
                                {/* <p>It is a long established fact that a reader will be</p> */}
                            </div>
                            <div className='service-box'>
                                <p>İnterior/Exterior designer</p>
                                {/* <p>It is a long established fact that a reader will be</p> */}
                            </div>
                            <div className='service-box'>
                                <p>Social media marketing (SMM)</p>
                                {/* <p>It is a long established fact that a reader will be</p> */}
                            </div>
                        </div>
                    </div>
                    <div className='why-us'></div>
                    {/* <div className='partners-home-page'>
                        <p className='header-txt'>Our <span>Partners</span></p>
                        <div className='partners-box'>
                            <img src={partners} alt="Partner 1" />
                            <img src={partners} alt="Partner 2" />
                            <img src={partners} alt="Partner 3" />
                            <img src={partners} alt="Partner 4" />
                            <img src={partners} alt="Partner 5" />
                        </div>
                    </div> */}
                    <div className='home-page-projects'>
                        <p className='header-txt'>Our <span>Projects</span></p>
                        <div className='projects-box'>
                            <div className='projectinbox1'>
                                 <a target='_blank'  rel="noopener noreferrer" href="https://setkaya.az/">

                                <img src={setkaya} alt="project" />
                                 </a>
                                <p>SETKAYA MMC</p>
                                <p>
SETKAYA MMC provides high-quality solutions for industrial and sports sectors, specializing in synthetic surfaces, installations, and digital marketing support.</p>
                            </div>
                            <div className='projectinbox2'>
                                 <a target='_blank'  rel="noopener noreferrer" href="https://ademinshaat.az/">

                                <img src={ademinsaat} alt="project" />
                                 </a>
                                <p>Ademinshaat</p>
                                <p>
Ademinshaat, a wholesaler of construction materials, ensures quality and value. They entered the digital space with our simple, functional website.</p>
                            </div>
                            <div className='projectinbox1'>
                                 <a target='_blank'  rel="noopener noreferrer" href="https://oilstar.az/">

                                <img src={oilstart} alt="project" />
                                 </a>
                                <p>Oil Start</p>
                                <p>
Since 2017, Oil Star has delivered innovative, reliable solutions for Azerbaijan's oil sector. In 2024, they partnered with us to expand their online reach.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='home-page-packages'>
                        <p className='header-txt packages-txt'>Our <span>Packages</span></p>
                        <div className='packages-box'>
                            <div className='package-item'>
                                <p>Title</p>
                                <p>$50<span>/month</span></p>
                                <ul>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                </ul>
                                <button>Button</button>
                            </div>
                            <div className='package-item'>
                                <p>Title</p>
                                <p>$50<span>/month</span></p>
                                <ul>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                </ul>
                                <button className='package2button'>Button</button>
                            </div>
                            <div className='package-item'>
                                <p>Title</p>
                                <p>$50<span>/month</span></p>
                                <ul>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                </ul>
                                <button>Button</button>
                            </div>
                        </div>
                    </div> */}
                </div>
                <Footer />
            </div>
        </>
    );
}

export default HomePage;
