import React from 'react'
import "./About.css";
import "../../assets/css/style.css";
import Header from "../../components/HeaderComponent/Header";
import Footer from "../../components/FooterComponent/Footer";
import Anar from './img/Anar.jpeg'
import Elnur from './img/Elnur.jpeg'
import Humbet from './img/Humbet.jpeg'
import Ibrahim from './img/Ibrahim.jpeg'
import Mursel from './img/Mursel.jpeg'
import Samil from './img/Samil.jpeg'
import Ilkin from './img/Ilkin.jpeg'
import Azim from './img/Azim.jpg'
import Macid from './img/Macid.jpg'
import Nihad from './img/Nihad.jpg'
import Murad from './img/Murad.jpg'





function About() {
    return (
        <>
            <div>
                <Header />
                <div className='about-container'>
                    <div className='about-header'>
                        <p>About <span>Us</span></p>
                        {/* <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p> */}
                    </div>
                    <div className='about-us-circles'>

                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Ilkin} alt="employee" />

                                </div>
                                <p>Ilkin</p>
                                <p>Founder | Ceo</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Murad} alt="employee" />

                                </div>
                                <p>Murad</p>
                                <p>Cfo</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Azim} alt="employee" />

                                </div>
                                <p>Azim</p>
                                <p>Cto</p>
                            </div>
                       
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Humbet} alt="employee" />

                                </div>
                                <p>Hümbət</p>
                                <p>Mobile developer</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Anar} alt="employee" />

                                </div>
                                <p>Anar</p>
                                <p>Full stack developer</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Elnur} alt="employee" />

                                </div>
                                <p>Elnur</p>
                                <p>Back end C# </p>
                            </div>
                       
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Mursel} alt="employee" />

                                </div>
                                <p>Mursel</p>
                                <p>Cybersecurity</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Macid} alt="employee" />


                                </div>
                                <p>Macid</p>
                                <p>Social Media Manager (SMM)</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Ibrahim} alt="employee" />

                                </div>
                                <p>İbrahim</p>
                                <p>Ux/ui Design</p>
                            </div>
                       
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Nihad} alt="employee" />

                                </div>
                                <p>Nihad</p>
                                <p>Business analytic</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Samil} alt="employee" />

                                </div>
                                <p>Samil</p>
                                <p>Java back end</p>
                            </div>
                            {/* <div className='circle-box'>
                                <div className="circle-image">
                            <img src={Elnur} alt="employee" />

                                </div>
                                <p>Elnur</p>
                                <p>Back end C# </p>
                            </div> */}
                       





                    </div>

                    
                    {/* <div className='about-us-circles'>
                        <div className='about-header'>
                            <p>Developer <span>Team</span></p>
                            <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>



                    </div> */}
                    {/* <div className='about-us-circles'>
                        <div className='about-header'>
                            <p>Designer <span>Team</span></p>
                            <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>



                    </div> */}

                </div>
                <Footer />

            </div>

        </>
    )
}

export default About
